const initialState = {
  locale: 'fr'
}

const reducer = (state = initialState,  action = {})  => {
  if(action.type === 'change_locale') {
    return {
      ...state,
      locale: action.payload.locale
    }
  }
  return state;
}

export default reducer;
