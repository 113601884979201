const initialState = {
  maxNumber: 90, //1-100
  numbers: [], //actual numbers remaining
  history: [], //history of past number
  animation: true, //true or false, enable or disable animation
  animationDuration: 4000, //ms (4sc) (2000-6000) (2s-6s), edit animation duration
  sound: false, //true or false, enable or disable sound
  isFinish: false, //true or false, true if game is finish
  autoplay: false, //true or false, enable or disable autoplay
  interval: 60000, //ms (60s) (30000-120000) (30s-2min), edit autoplay interval
  needToReload: false, //true or false, true if need to reload game
};

const reducer = (state = initialState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case INITIALIZE_GAME:
    case RESET_GAME:
      newState.numbers.length = 0;
      newState.history.length = 0;
      newState.isFinish = false;
      newState.needToReload = false;
      newState.numbers = Array.from(
        { length: newState.maxNumber },
        (_, i) => i + 1
      );
      return newState;

    case PLAY_GAME:
      newState.history.splice(0, 0, newState.numbers[action.payload]);
      newState.numbers.splice(action.payload, 1);
      if (newState.history.length > newState.maxNumber) {
        newState.isFinish = true;
      }
      return newState;

    case CHANGE_MAX_NUMBER:
      if (action.payload >= 10 && action.payload <= 100) {
        newState.maxNumber = action.payload;
        newState.needToReload = true;
      }
      return newState;

    case CHANGE_ANIMATION:
      newState.animation = action.payload;
      return newState;

    case CHANGE_ANIMATION_DURATION:
      newState.animationDuration = action.payload;
      return newState;

    case CHANGE_SOUND:
      newState.sound = action.payload;
      return newState;

    case CHANGE_AUTOPLAY:
      newState.autoplay = action.payload;
      newState.needToReload = true;
      return newState;

    case CHANGE_INTERVAL:
      newState.interval = action.payload;
      return newState;

    default:
      return newState;
  }
};

export const INITIALIZE_GAME = "INITIALIZE_GAME";
export const PLAY_GAME = "PLAY_GAME";
export const RESET_GAME = "RESET_GAME";
export const CHANGE_MAX_NUMBER = "CHANGE_MAX_NUMBER";
export const CHANGE_ANIMATION = "CHANGE_ANIMATION";
export const CHANGE_ANIMATION_DURATION = "CHANGE_ANIMATION_DURATION";
export const CHANGE_SOUND = "CHANGE_SOUND";
export const CHANGE_AUTOPLAY = "CHANGE_AUTOPLAY";
export const CHANGE_INTERVAL = "CHANGE_INTERVAL";
export default reducer;
