import React from 'react';
// Redux
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
// i18n wrapper
import LanguageProvider from "utils/LanguageProvider";

// Reducers
import languageReducer from 'state/language';
import lotoReducer from 'state/loto.js'

// i18n Translations
import enTranslation from "./lang/en";
import frTranslation from "./lang/fr";

const withProvider = ({ element }) => {

  const rootReducer = combineReducers({
    lang: languageReducer,
    loto: lotoReducer
  });
  const composedEnhancer = compose(applyMiddleware(thunkMiddleware));
  const store = createStore(rootReducer, composedEnhancer);

  const messages = {
    en: enTranslation,
    fr: frTranslation
  }

  return (
    <Provider store={store}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Roboto&family=Lato&family=Raleway&display=swap" rel="stylesheet"/>
      <LanguageProvider messages={messages}>
        {element}
      </LanguageProvider>
    </Provider>
  )
}

export default withProvider;
